import axios from 'axios'
import { createRouter, createWebHashHistory } from 'vue-router'
import actions from '../actions'
import { showToast } from 'vant'
//import { setCookie } from '../util';
// import { checkLogin } from '../util/agent';

const routes = [
    {
        path: '/login',
        name: 'Login',
        meta: { title: "用户登录" },
        component: () => import('../views/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        meta: { title: "注册" },
        component: () => import('../views/Register.vue')
    },
	{
	    path: '/registerFrom',
	    name: 'registerFrom',
	    meta: { title: "註冊表單" },
	    component: () => import('../views/RegisterFrom.vue')
	},
	{
	    path: '/resetPassword',
	    name: 'resetPassword',
	    meta: { title: "找回密碼" },
	    component: () => import('../views/ResetPassword.vue')
	},
	
    {
        path: '/appflow',
        name: 'appflow',
        meta: { title: "申请流程" },
        component: () => import('../views/ApplicationFlow.vue')
    },
    {
        path: '/forget',
        meta: { title: "忘记密码" },
        component: () => import('../views/Forget.vue')
    },
    {
        path: '/agentgroup',
        name: 'agentgroup',
        icon: 'icon-home',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'agentgroup',
                icon: 'icon-home',
                title: '经销商中心',
                path: '/agentgroup',
                meta: { title: '经销商中心' },
                component: () => import('../views/AgentGroup.vue')
            },
            {
                name: 'agrouplist',
                icon: 'icon-home',
                title: '经销商列表',
                path: '/agentgroup/list',
                meta: { title: '经销商列表' },
                component: () => import('../views/AGroupList.vue')
            },
            {
                name: 'distributors',
                icon: 'icon-home',
                title: '分销商',
                path: '/agentgroup/distributors',
                meta: { title: '分销商' },
                component: () => import('../views/AgentDistributors.vue')
            },
            {
                name: 'retailers',
                icon: 'icon-home',
                title: '零售商',
                path: '/agentgroup/retailers',
                meta: { title: '零售商' },
                component: () => import('../views/AgentRetailers.vue')
            },
            {
                name: 'invite',
                icon: 'icon-home',
                title: '邀请注册',
                path: '/agentgroup/invite',
                meta: { title: '邀请注册' },
                component: () => import('../views/AgentInvite.vue')
            },
        ]
    },
    {
        path: '/',
        name: 'home',
        icon: 'icon-home',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'home',
                ismainmenu: true,
                showmainmenu: true,
                icon: 'icon-home',
                title: '首页',
                path: '/',
                meta: { title: '首页' },
                component: () => import('../views/Home.vue')
            },
        ]
    },
    {
        path: '/product',
        name: 'productcenter',
        icon: 'icon-wenxiong',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'product',
                ismainmenu: true,
                showmainmenu: true,
                icon: 'icon-wenxiong',
                title: '产品中心',
                path: '/product',
                meta: { title: '产品中心' },
                component: () => import('../views/Product.vue')
            },
			{
			    name: 'productExchange',
			    icon: 'icon-wenxiong',
			    title: '产品中心',
			    path: '/productExchange',
			    meta: { title: '产品中心' },
			    component: () => import('../views/ProductExchange.vue')
			},
			{
			    name: 'productExchangeTwo',
			    icon: 'icon-wenxiong',
			    title: '产品中心',
			    path: '/productExchangeTwo',
			    meta: { title: '产品中心' },
			    component: () => import('../views/ProductExchangeTwo.vue')
			},
			{
			    name: 'productExchangeThree',
			    icon: 'icon-wenxiong',
			    title: '产品中心',
			    path: '/productExchangeThree',
			    meta: { title: '产品中心' },
			    component: () => import('../views/ProductExchangeThree.vue')
			},
            {
                name: 'productdetail',
                icon: 'icon-wenxiong',
                title: '产品详情',
                path: '/product/detail',
                meta: { title: '产品详情' },
                component: () => import('../views/ProductDetail.vue')
            },
            {
                name: 'search',
                icon: 'icon-wenxiong',
                title: '搜索',
                path: '/product/search',
                meta: { title: '搜索' },
                component: () => import('../views/Search.vue')
            },
        ]
    },
    {
        path: '/cart',
        name: 'cart',
        icon: 'icon-cart-02',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'cart',
                ismainmenu: true,
                showmainmenu: true,
                icon: 'icon-cart-02',
                title: '购物车',
                path: '/cart',
                meta: { title: '购物车' },
                component: () => import('../views/Cart.vue')
            },
        ]
    },
    {
        path: '/my',
        name: 'my',
        icon: 'icon-my',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'my',
                ismainmenu: true,
                showmainmenu: true,
                icon: 'icon-my',
                title: '我的',
                path: '/my',
                meta: { title: '我的' },
                component: () => import('../views/My.vue')
            },
            {
                name: 'acontacts',
                title: '团队成员',
                path: '/acontacts',
                meta: { title: '团队成员' },
                component: () => import('../views/AgentContacts.vue')
            },
            {
                name: 'viewpdf',
                title: '授权证书',
                path: '/viewpdf',
                meta: { title: '授权证书' },
                component: () => import('../views/ViewPDF.vue')
            },
            {
                name: 'profile',
                title: '个人资料',
                path: '/profile',
                meta: { title: '个人资料' },
                component: () => import('../views/MyProfile.vue')
            },
        ]
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'orderlist',
                title: '订单列表',
                path: '/order',
                meta: { title: '订单列表' },
                component: () => import('../views/Order.vue')
            },
            {
                name: 'placeorder',
                title: '提交订单',
                path: '/order/placeorder',
                meta: { title: '提交订单' },
                component: () => import('../views/OrderPre.vue')
            },
            {
                name: 'orderdetail',
                title: '订单详情',
                path: '/order/detail',
                meta: { title: '订单详情' },
                component: () => import('../views/OrderDetail.vue')
            }
        ]
    },
	
	{
	    path: '/orderTariff',
	    name: 'orderTariff',
	    component: () => import('../layout/Layout.vue'),
	    children: [
	        {
	            name: 'orderTarifflist',
	            title: '關稅訂單列表',
	            path: '/orderTariff',
	            meta: { title: '關稅訂單列表' },
	            component: () => import('../views/OrderTariff.vue')
	        },
			{
			    name: 'orderTariffDetail',
			    title: '關稅訂單详情',
			    path: '/orderTariffDetail',
			    meta: { title: '關稅訂單详情' },
			    component: () => import('../views/OrderTariffDetail.vue')
			}
	    ]
	},
	
	
	{
	    path: '/coupon',
	    name: 'coupon',
	    component: () => import('../layout/Layout.vue'),
	    children: [
	        {
	            name: 'couponList',
	            title: '簽約列表',
	            path: '/coupon',
	            meta: { title: '簽約列表' },
	            component: () => import('../views/Coupon.vue')
	        },
	    ]
	},
    {
        path: '/address',
        name: 'address',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'addresslist',
                title: '地址列表',
                path: '/address',
                meta: { title: '地址列表' },
                component: () => import('../views/Address.vue')
            },
            {
                name: 'addressdetail',
                title: '地址详情',
                path: '/address/detail',
                meta: { title: '地址详情' },
                component: () => import('../views/AddressDetail.vue')
            }
        ]
    },
	{
	    path: '/contract',
	    name: 'contract',
	    component: () => import('../layout/Layout.vue'),
	    children: [
	        {
	            name: 'contractList',
	            title: '簽約列表',
	            path: '/contract',
	            meta: { title: '簽約列表' },
	            component: () => import('../views/Contract.vue')
	        },
	    ]
	},
	
	
    {
        path: '/credit',
        name: 'recharge',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'rechargecredit',
                title: '首充报单',
                path: '/credit/recharge',
                meta: { title: '首充报单' },
                component: () => import('../views/CreditRecharge.vue')
            },
			{
			    name: 'rechargecreditMoney',
			    title: '货款充值',
			    path: '/credit/rechargeMoney',
			    meta: { title: '货款充值' },
			    component: () => import('../views/CreditRechargeMoney.vue')
			},
			{
			    name: 'brandRecharge',
			    title: '品牌使用費充值',
			    path: '/brand/recharge',
			    meta: { title: '品牌使用費充值' },
			    component: () => import('../views/BrandRecharge.vue')
			},
			
			{
			    name: 'tariffCredit',
			    title: '關稅單支付',
			    path: '/tariffCredit',
			    meta: { title: '關稅單支付' },
			    component: () => import('../views/TariffCredit.vue')
			},
			
            {
                name: 'rechargelogs',
                title: '货款明细',
                path: '/credit/logs',
                meta: { title: '货款明细' },
                component: () => import('../views/CreditLogs.vue')
            },
            {
                name: 'rechargelogs2',
                title: '充值记录',
                path: '/credit/rechargelogs',
                meta: { title: '充值记录' },
                component: () => import('../views/CreditRechargeLogs.vue')
            },
            {
                name: 'credit',
                title: '货款账户',
                path: '/credit',
                meta: { title: '货款账户' },
                component: () => import('../views/Credit.vue')
            }
        ]
    },
    {
        path: '/income',
        name: 'shouyi',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'income',
                title: '收益賬戶',
                path: '/income',
                meta: { title: '收益賬戶' },
                component: () => import('../views/Income.vue')
            },
            {
                name: 'incomeaccount',
                title: '提现账户管理',
                path: '/income/account',
                meta: { title: '提现账户管理' },
                component: () => import('../views/IncomeAccount.vue')
            },
            {
                name: 'incomeaccountedit',
                title: '提现账户编辑',
                path: '/income/account/edit',
                meta: { title: '提现账户管理' },
                component: () => import('../views/IncomeAccountEdit.vue')
            },
            {
                name: 'incomewithdraw',
                title: '提現',
                path: '/income/withdraw',
                meta: { title: '提現' },
                component: () => import('../views/IncomeWithdraw.vue')
            },
            {
                name: 'incomewithdrawlogs',
                title: '提現明細',
                path: '/income/withdrawlogs',
                meta: { title: '提現明細' },
                component: () => import('../views/IncomeWithdrawLogs.vue')
            },
            {
                name: 'incometoberecorded',
                title: '待结算',
                path: '/income/toberecorded',
                meta: { title: '待结算' },
                component: () => import('../views/IncomeToBeRecorded.vue')
            },
            {
                name: 'incomelogs',
                title: '收益明细',
                path: '/income/logs',
                meta: { title: '收益明细' },
                component: () => import('../views/IncomeLogs.vue')
            }
        ]
    },
    {
        path: '/announce',
        name: 'notice',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                name: 'announce',
                title: '平台公告',
                path: '/announce',
                meta: { title: '平台公告' },
                component: () => import('../views/Announce.vue')
            }
        ]
    },
	{
	    path: '/custom',
	    name: 'custom',
	    component: () => import('../layout/Layout.vue'),
	    children: [
			{
			    name: 'shen',
			    title: '用戶待審核',
			    path: '/custom/shen',
			    meta: { title: '用戶待審核' },
			    component: () => import('../views/CustomSigning.vue')
			},
			
	        {
	            name: 'signing',
	            title: '用戶待簽約',
	            path: '/custom/signing',
	            meta: { title: '用戶待簽約' },
	            component: () => import('../views/CustomSigningTwo.vue')
	        },
			{
			    name: 'payment',
			    title: '用戶待打款',
			    path: '/custom/payment',
			    meta: { title: '用戶待打款' },
			    component: () => import('../views/CustomPayment.vue')
			},
			{
			    name: 'paymentShen',
			    title: '待打款審核',
			    path: '/custom/paymentShen',
			    meta: { title: '待打款審核' },
			    component: () => import('../views/CustomPaymentShen.vue')
			},
	    ]
	},
	{
	    path: '/settings',
	    name: 'settings',
	    component: () => import('../layout/Layout.vue'),
	    children: [
	        {
	            name: 'myContract',
	            title: '我的簽約',
	            path: '/settings/myContract',
	            meta: { title: '我的簽約' },
	            component: () => import('../views/myContract.vue')
	        },
			{
			    name: 'changePw',
			    title: '修改密碼',
			    path: '/settings/changePw',
			    meta: { title: '修改密碼' },
			    component: () => import('../views/changePw.vue')
			},
			{
			    name: 'settings',
			    title: '設置',
			    path: '/settings',
			    meta: { title: '設置' },
			    component: () => import('../views/Settings.vue')
			},
	    ]
	},
	{
	    path: '/depositMoney',
	    name: 'depositMoney',
	    component: () => import('../layout/Layout.vue'),
	    children: [
	        {
	            name: 'depositMoney',
	            title: '品牌使用費',
	            path: '/depositMoney',
	            meta: { title: '品牌使用費' },
	            component: () => import('../views/DepositMoney.vue')
	        }
	    ]
	},
	{
	    path: '/depositMoneyLog',
	    name: 'depositMoneyLog',
	    component: () => import('../layout/Layout.vue'),
	    children: [
	        {
	            name: 'depositMoneyLog',
	            title: '品牌使用費',
	            path: '/depositMoneyLog',
	            meta: { title: '品牌使用費' },
	            component: () => import('../views/DepositMoneyLog.vue')
	        }
	    ]
	},
	{
	    path: '/teacher',
	    name: 'teacher',
	    component: () => import('../layout/Layout.vue'),
	    children: [
	        {
	            name: 'teacher',
	            title: '导师页面',
	            path: '/teacher',
	            meta: { title: '品牌使用費' },
	            component: () => import('../views/Teacher.vue')
	        },
			{
			    name: 'teacherLog',
			    title: '申請導師列表',
			    path: '/teacherLog',
			    meta: { title: '申請導師列表' },
			    component: () => import('../views/TeacherLogs.vue')
			},
			{
			    name: 'teacherRule',
			    title: '導師規則',
			    path: '/teacherRule',
			    meta: { title: '導師規則' },
			    component: () => import('../views/TeacherRule.vue')
			},
			{
			    name: 'teacherSchedule',
			    title: '導師進度',
			    path: '/teacherSchedule',
			    meta: { title: '導師進度' },
			    component: () => import('../views/TeacherSchedule.vue')
			},
			{
			    name: 'teacherAddTypeThree',
			    title: '導師線下沙龍申請',
			    path: '/teacherAddTypeThree',
			    meta: { title: '導師線下沙龍申請' },
			    component: () => import('../views/TeacherAddTypeThree.vue')
			},
			{
			    name: 'teacherAddTypeFour',
			    title: '導師申請線上課程',
			    path: '/teacherAddTypeFour',
			    meta: { title: '導師申請線上課程' },
			    component: () => import('../views/TeacherAddTypeFour.vue')
			},
			{
			    name: 'teacherAddTypeFourTwo',
			    title: '導師申請線上課程文件',
			    path: '/TeacherAddTypeFourTwo',
			    meta: { title: '導師申請線上課程文件' },
			    component: () => import('../views/TeacherAddTypeFourTwo.vue')
			},
			{
			    name: 'teacherList',
			    title: '導師週期列表',
			    path: '/teacherList',
			    meta: { title: '導師週期列表' },
			    component: () => import('../views/TeacherList.vue')
			},
			
	    ]
	},
	{
	    name: 'certificate',
	    title: '授權證書',
	    path: '/certificate',
	    meta: { title: '授權證書' },
	    component: () => import('../views/Certificate.vue')
	},
	
	{
	    path: '/historyMoney',
	    name: 'historyMoney',
	    component: () => import('../layout/Layout.vue'),
	    children: [
	        {
	            name: 'historyMoney',
	            title: '歷史積分',
	            path: '/historyMoney',
	            meta: { title: '歷史積分' },
	            component: () => import('../views/HistoryMoney.vue')
	        },
			{
			    name: 'historyMoneyLog',
			    title: '歷史積分明細',
			    path: '/historyMoneyLog',
			    meta: { title: '歷史積分明細' },
			    component: () => import('../views/HistoryMoneyLog.vue')
			}
	    ]
	},
	
	
]

const router = createRouter({
	mode:"hash",
    history: createWebHashHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    console.log('to => ', to.name);
    console.log('from => ', from.name);
    console.log('next => ', next);
	
	

	

	
	
	
    axios.get(actions.memberBrandStatus).then((res) => {
		
		
		
		
		if(res.data.code==401 && (to.name != 'Login' && to.name != 'register'  && to.name != 'resetPassword')){
			 router.push("/login");
			 return;
		}
		
		 //補全信息
		 if(res.data.data.inviteStatus==5 && to.name != 'registerFrom'){
			  router.push("/registerFrom");
			  return;
		 }
		 //准经销商待審核
		 if(res.data.data.inviteStatus==1 && to.name != 'shen'){
		 			  router.push("/custom/shen");
		 }
		 
		 if(res.data.data.authorization==0  && to.name != 'signing'  && res.data.data.inviteStatus==2 ){
		 			  router.replace("/custom/signing");
					  return;
		 }
		 if(res.data.data.purchase==0 && to.name != 'signing'    && res.data.data.inviteStatus==2 ){
		 			 router.replace("/custom/signing");
		 			 return;
		 }
		
		 
		//开始赚钱 更新保证金状态 
		if (res.data.data.depositFlag==1 && res.data.data.depositStatus == 2){
			axios.get(actions.goCenter).then(()=>{
				 next();
			})
			
		}

        //用戶待打款
		if(res.data.data.levelType==1 && res.data.data.purchase==1 && res.data.data.authorization==1){
						if(res.data.data.taskStatus==0  && to.name != 'rechargecredit'){
							router.replace({
							  path: "/credit/recharge",
							});	
						}
						if(res.data.data.taskStatus==1 && to.name != 'paymentShen' ){
							 //打款成功 待後台確認
							  router.replace({
							  path: "/custom/paymentShen",
							});	
						}
		}
		if (res.data.data.inviteStatus!=5 && res.data.data.status==0 && to.name != 'Login'){
			showToast({message:"無法登錄請聯繫客服"});
			 router.push("/login");
			 return;
		}else{
			next();
		}
		
        

	})
    		 
		 

    
	/*
    if (to.name == 'appflow') {
        next();
    } else {
        checkLogin().then((data) => {
            //console.log(data);
			next();
            if (data != 'OK') {
                setCookie("agentloginid", "");
                setCookie("agentlogintoken", "");
                if (to.name != 'Login' && to.name != 'register') {
                    router.push("/login");
                } else {
                    next();
                }
            } else {
                next();
            }
        }).catch((error) => {
            console.log('router.index.routerCheckLogin.error', error);
            next();
        });
    }
	*/
})
export default router



